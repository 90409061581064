<script lang="ts" setup>
  import type { RouteLocationNamedRaw, RouteLocationRaw } from 'vue-router';

  interface IRoute {
    name: string;
    to: RouteLocationNamedRaw;
  }
  const items: IRoute[] = [
    { name: 'Каталог', to: { name: 'browse' } },
    { name: 'Избранное', to: { name: 'favorites' } },
    { name: 'Спорт', to: { name: 'sports' } },
    { name: 'Тарифы', to: { name: 'subscriptions' } },
  ];
  const route = useRoute();
  const checkActive = (link: IRoute) => route.path?.includes(String(link.to.name));
</script>

<template>
  <ul class="header-menu">
    <li v-for="(link, index) in items" :key="index" class="header-menu__item">
      <nuxt-link class="header-menu__link" :class="{ active: checkActive(link) }" :to="link.to">
        {{ link.name }}
      </nuxt-link>
    </li>
  </ul>
</template>

<style lang="scss">
  .header-menu {
    display: flex;
    gap: 16px;
    &__item {
      padding: 10px 8px;
    }
    &__link {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.4);
      &:hover {
        color: #eeeeee;
      }
      &.active {
        color: $main_white;
        font-weight: 600;
      }
    }
    @media (max-width: $tablet) {
      display: none;
    }
  }
</style>
